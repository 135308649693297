import React from "react";
import '../css/Global.css'
import '../css/index.css'
// import './bootstrap.min.css'
// import '../css/font-awesome.min.css'
import '../css/Fontstyle.css'

function Footer() {
    return (
        <>
            <section id="footer" className="clearfix">
                <div className="container">
                    <div className="row">
                        <div className="footer_1 clearfix">
                            <div className="col-sm-4">
                                <div className="footer_1l clearfix">
                                    <h2 className="mgt"><a href="#"><span className="col_1">Hexa</span>Tech</a></h2>
                                    <p>Our commitment to quality has earned us global trust...</p>
                                    <div className="footer_1li cleafix">
                                        <span className="col_1"><i className="fa fa-headphones"></i></span>
                                        <h5>Customer Support</h5>
                                        <h4><a href="#">(+91) 8850440600</a></h4>
                                        <h4><a href="#" className="gm-trad">hexatechtradingco@gmail.com</a></h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="footer_1m clearfix">
                                        <ul className="social-network social-circle">
                                            {/* <li><a href="#" className="icoRss" title="Rss"><i className="fa fa-rss"></i></a></li> */}
                                            <li><a href="https://www.facebook.com/share/rmWjETRDxvZA4XJp/?mibextid=qi2Omg" className="icoFacebook" title="Facebook" target="_blank"><i className="fa fa-facebook"></i></a></li>
                                            <li><a href="#" className="icoTwitter" title="Twitter"><i className="fa fa-twitter"></i></a></li>
                                            <li><a href="https://www.instagram.com/hexatechtradingco?igsh=MTBtenNvY2tkeWkxdQ==" className="icoInstagram" title="Instagram" target="_blank"><i className="fa fa-instagram"></i></a></li>
                                            {/* <FontAwesomeIcon icon="fa-brands fa-instagram" /> */}
                                            <li><a href="#" className="icoLinkedin" title="Linkedin"><i className="fa fa-linkedin"></i></a></li>
                                        </ul>
                                </div>
                            </div>
                            <div className="col-sm-4">
                            <div className="footer_1li cleafix">
                                        <span className="col_1"><i className="fa fa-map-marker"></i></span>
                                        <h5>Address</h5>
                                        <h4>B/137 1st Floor, Orchid Complex</h4>
                                        <h4>Opp Food Point, Pirojpura Road</h4>
                                        <h4>Chhapi Banaskantha Gujrat-385210</h4>
                                    </div>
                            </div>
                            {/* <div className="col-sm-4">
                                <div className="footer_1r clearfix">
                                    <h4>Information</h4>
                                    <ul>
                                        <li><a href="#">Delivery <span className="pull-right">Legal Notice</span></a></li>
                                        <li><a href="#">About Us <span className="pull-right">Secure payment</span></a></li>
                                        <li><a href="#">Contact Us <span className="pull-right">Sitemap</span></a></li>
                                        <li><a href="#">Stores <span className="pull-right">My Account</span></a></li>
                                    </ul>
                                </div>
                            </div> */}
                        </div>
                        <div className="footer_2 text-center clearfix">
                            <div className="col-sm-12">
                                <p className="mgt">© 2024 HexaTechTradingCo. All Rights Reserved | Design by <a className="col_1" href="http://www.hexatechtradingco.com/">HexaTechTradingCo</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Footer